import { createSelector } from 'reselect';

import { selectUpliftSupportedCurrencies } from '@/ducks/common/settings';
import { selectCurrencyCode } from '@/ducks/filters/selectors';
import { selectInvoiceCurrencyCode } from '@/ducks/pages/summary/selectors';
import { type RootState } from '@/store';

export const selectUplift = (state: RootState) => state?.uplift;

export const selectUpliftActiveCurrencyCode = (state: RootState) => selectUplift(state)?.activeCurrencyCode;

export const selectUpliftIsStarted = (state: RootState) => !!selectUpliftActiveCurrencyCode(state);

export const selectRelevantCurrencyCode = (state: RootState) =>
  selectInvoiceCurrencyCode(state) || selectCurrencyCode(state);

export const selectUpliftIsReady = (state: RootState) =>
  selectUpliftActiveCurrencyCode(state) === selectRelevantCurrencyCode(state);

export const selectIsUpliftEligible = (state: RootState) => selectUplift(state)?.isEligible;

export const selectUpliftApplicable = createSelector(
  [selectUpliftIsReady, selectUpliftSupportedCurrencies, selectRelevantCurrencyCode],
  (isUpliftReady, currencies, currencyCode) => isUpliftReady && currencies.includes(currencyCode),
);
