/* eslint-disable */

/**
 * This code is lifted from https://developer.uplift.com/web/5.0/loading-payment-js
 * and does not need to be linted
 */
function load(u, p, l, i, f, t, b, j) {
  u.UpLiftPlatformObject = f;
  (u[f] =
    u[f] ||
    function () {
      (u[f].q = u[f].q || []).push(arguments);
    }),
    (u[f].l = 1 * new Date());
  (b = p.createElement(l)), (j = p.getElementsByTagName(l)[0]);
  b.async = 1;
  b.src = `${i}?id=${t}`;
  j.parentNode.insertBefore(b, j);
  let o = window.location.host.match(/[w-]+.w{2,3}(:d+)?$/);
  if (o) {
    o = o[0];
  }
  u[f]('create', t, o);
}

export default load;
