import pick from 'lodash/pick';

import type { TStorePersistentAligner } from '@/ducks/persistent/types';
import type { RootState } from '@/store';

import { setIsPayInFullPromoAppliedFlag } from '@/actions';
import { SUMMARY_CALCULATE_INVOICE_SUCCESS } from '@/constants/actionTypes';
import { selectCalculateInvoiceData, selectIsPayInFullPromoApplied } from '@/ducks/pages/summary/selectors';
import { setPaidAmounts } from '@/ducks/payment/paidAmounts/slice';
import { selectPaymentPaidAmounts } from '@/ducks/payment/selectors';
import { PERSISTENT_STORE_KEYS as TRAVEL_PARTY_STORE_KEYS } from '@/ducks/travelParty/constants';
import { selectTravelParty } from '@/ducks/travelParty/selectors';
import { prefillTravelParty, type TStoreTravelParty } from '@/ducks/travelParty/slice';
import { type TPaymentPaidAmount } from '@/infra/types/payment';
import { type CalculateInvoiceState } from '@/reducers/voyagePlanner/summary/calculateInvoice';

// Please start the storagePath with segment 'persistent' unless there is a special reason to do otherwise

const aligners: TStorePersistentAligner[] = [
  {
    action: (data: unknown) => prefillTravelParty(data as TStoreTravelParty),
    inSession: true,
    selector: (store: RootState) => pick(selectTravelParty(store), TRAVEL_PARTY_STORE_KEYS),
    storagePath: ['persistent', 'travelParty'],
  },
  {
    action: (data: unknown) => setPaidAmounts(data as TPaymentPaidAmount[]),
    inSession: true,
    selector: selectPaymentPaidAmounts,
    storagePath: ['persistent', 'payment', 'paidAmounts'],
  },
  {
    action: (data: unknown) => setIsPayInFullPromoAppliedFlag(data as boolean),
    inSession: true,
    selector: selectIsPayInFullPromoApplied,
    storagePath: ['persistent', 'payment', 'isPayInFullPromoApplied'],
  },
  {
    action: (data: unknown) => ({
      payload: data as CalculateInvoiceState['data'],
      type: SUMMARY_CALCULATE_INVOICE_SUCCESS,
    }),
    inSession: true,
    selector: selectCalculateInvoiceData,
    storagePath: ['persistent', 'summary', 'calculateInvoice'],
  },
];

export default aligners;
