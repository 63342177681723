import { type LookupSlice } from '@/ducks/common/lookup/types';
import { type ResourcesSlice } from '@/ducks/common/resources/types';
import { type SettingsSlice } from '@/ducks/common/settings/types';
import { env } from '@/environment';
import simpleFetch from '@/features/api/simpleFetch';
import { simpleFetchWithAuth, simpleFetchWithCmsAuth, type TFetchOptions } from '@/features/api/simpleFetch';
import { type VoyageDataResponse } from '@/infra/types/voyageInfo/package';
import { type CMSResponse, type TCampaign, type TLegalContent } from '@/types/cms';

export const fetchSettings = (options: TFetchOptions) =>
  simpleFetch<SettingsSlice>(`${env.REST_BFF_URL}/settings`, {
    ...options,
    headers: {
      ...options.headers,
      Authorization: `Basic ${env.CONFIG_BASIC_TOKEN}`,
    },
  });

export const fetchResources = (options: TFetchOptions) =>
  simpleFetchWithAuth<ResourcesSlice>(`${env.REST_BFF_URL}/resources`, options);

export const fetchLookup = (options: TFetchOptions) =>
  simpleFetchWithAuth<LookupSlice>(`${env.REST_BFF_URL}/lookup`, options);

export const fetchVoyageData = (options: TFetchOptions) =>
  simpleFetchWithAuth<VoyageDataResponse>(`${env.REST_BFF_URL}/voyagedata`, options);

export type TFetchLegalContentsUrlParams = {
  name: string;
};

export const fetchLegalContents = (options: TFetchOptions<TFetchLegalContentsUrlParams>) =>
  simpleFetchWithCmsAuth<CMSResponse<TLegalContent>>(`${env.CMS_BASE_URL}/legalcontents`, options);

export type TFetchVVCampaignsUrlParams = {
  externalId: string;
};

export const fetchVVCampaigns = (options: TFetchOptions<TFetchVVCampaignsUrlParams>) =>
  simpleFetchWithCmsAuth<CMSResponse<TCampaign>>(`${env.CMS_BASE_URL}/vv-campaigns`, options);

export const fetchImage = (url: string, options?: TFetchOptions) =>
  simpleFetch<string>(url, { ...options, asTextResult: true, next: { revalidate: +env.NEXT_CACHE_TIME } });
