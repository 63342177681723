import PropTypes from 'prop-types';
import React from 'react';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import CMSContext from '@/contexts/CMSContext';
import { fetchLegalContents, fetchVVCampaigns } from '@/features/api/endpoints/common';

// Helper function can do additional processing on the data as needed
const getHelper = (cmsData, key) => get(cmsData, key);

const getCelebrationsHelpper = (celebrations, key) => get(celebrations, key);

class CMSContextProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cmsCelebrations: null,
      cmsData: null,
      hasAuthToken: false,
    };
  }

  componentDidUpdate() {
    const { hasAuthToken } = this.state;
    const { authToken, celebrations } = this.props;
    if (celebrations && !isEmpty(authToken) && !hasAuthToken) {
      this.fetchCelebrationsInfo();
    } else if (!isEmpty(authToken) && !hasAuthToken) {
      this.fetchMGMData();
    }
  }

  async fetchCelebrationsInfo() {
    try {
      this.setState({ hasAuthToken: true });
      const data = await fetchVVCampaigns({ params: { externalId: 'CEL' } });
      // Assumes the Magnolia endpoint will return exactly one return
      const cmsData = {
        celebrations: data.results[0],
      };
      this.setState({ cmsCelebrations: cmsData }, () => this.fetchLegalContentData(true));
    } catch (err) {
      console.error(err);
    }
  }

  async fetchLegalContentData(isCelebrations) {
    const { cmsCelebrations, cmsData } = this.state;

    try {
      // The legal content name must be retrieved from the MGM content first
      const blockName = !isCelebrations
        ? getHelper(cmsData, 'mgm.legal.name')
        : getCelebrationsHelpper(cmsCelebrations, 'celebrations.legal.name');
      const data = await fetchLegalContents({ params: { name: blockName } });
      // Assumes the Magnolia endpoint will return exactly one result
      const legalCmsData = {
        legal: data.results[0],
        ...cmsData,
        ...cmsCelebrations,
      };
      if (isCelebrations) {
        this.setState({ cmsCelebrations: legalCmsData });
      } else {
        this.setState({ cmsData: legalCmsData });
      }
    } catch (err) {
      console.error(err);
    }
  }

  async fetchMGMData() {
    try {
      this.setState({ hasAuthToken: true });
      const data = await fetchVVCampaigns({ params: { externalId: 'MGM' } });
      // Assumes the Magnolia endpoint will return exactly one return
      const cmsData = {
        mgm: data.results[0],
      };
      this.setState({ cmsData }, () => this.fetchLegalContentData());
    } catch (err) {
      console.error(err);
    }
  }

  render() {
    const { campaign, children, legalContent } = this.props;
    const campaignWithContent = { ...campaign, legal: legalContent };
    const alreadySelectedCampaign = { celebrations: campaignWithContent, mgm: campaignWithContent };

    return (
      <>
        <CMSContext.Provider
          value={{
            content: alreadySelectedCampaign,
            get: (key) => get(alreadySelectedCampaign, key),
          }}
        >
          {children}
        </CMSContext.Provider>
      </>
    );
  }
}

const propTypes = {
  authToken: PropTypes.string,
  celebrations: PropTypes.bool,
  children: PropTypes.element,
};

const defaultProps = {
  authToken: null,
  celebrations: true,
  children: <></>,
};

CMSContextProvider.propTypes = propTypes;
CMSContextProvider.defaultProps = defaultProps;

export default CMSContextProvider;
